import { HashRouter, Routes, Route } from "react-router-dom";
import SignUpV2 from "./Pages/Login/SignUpV2";

import Home from "./Pages/Home/Home";
import ContactUs from "./Pages/Contact/ContactUs";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { PrivacyPolicy } from "./components/Privacy/PrivacyPolicy";
import About from "./Pages/About/About";

// Login
function App() {
  return (
    <div>
      {/* <Loader /> */}
      <HashRouter>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/signup" element={<SignUpV2 />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
